const urlCore = 'https://grow.gestionfactura.com/'
const sandbox = true
const pais = 'AR'
const urlPickit = 'https://pickit.develone.com/'
const invoiceType = 'factura_pedido'
const google_map_apikey = 'AIzaSyCChh6DMa1iGt9qnc6yFIAiNLg5_6vji_8'
const map_id = 'c3c26ec59e3adbf'
const enMantenimiento = false
// DESCONOCIDO
let unknown = '1'
export function getUnknown() {
  return unknown
}
export function setUnknown(value) {
  unknown = value
}

export { urlCore, urlPickit, invoiceType, google_map_apikey, map_id, sandbox, pais, enMantenimiento }
